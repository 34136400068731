.property-detail-container {
  /* width: 100%; */
  position: relative;
  z-index: 999999;
  height: 100%;
}

.property-detail-footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 5px 10px 10px 10px #00000040;
  z-index: 999;
  background-color: #fff;
}

.property-detail-close {
  position: absolute;
  top: 12px;
  right: 24px;
  cursor: pointer;
  z-index: 99;
}

.property-detail-close-icon {
  width: 24px;
  height: 24px;
  color: #000;
}

.property-detail-btn {
  width: 170px !important;
}

.property-detail-content {
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  min-height: 100vh;
}
.property-images {
  width: 100%;
}

.Property-detail-title {
  color: rgba(80, 80, 80, 1);
  font-size: 18px;
}

.verification-pending-msg {
  color: #474747;
  padding: 10px 0;
  font-size: 16px;
}

.property-active-image-container {
  width: 100%;
  min-width: 550px;
  min-height: 350px;
  position: relative;
}

.active-property-img {
  object-fit: cover;
  width: 100%;
  border-radius: 3.88px;
}

.property-image-box {
  width: 125px;
  min-width: 120px;
  height: 100px;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 3.2px;
}

.property-img {
  width: 125px;
  height: 100px;
  object-fit: cover;
}

.property-images-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-direction: nowrap;
  width: 100%;
  overflow: scroll;
  margin-top: 10px;
}

.property-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0;
  margin-top: 20px;
}

.property-info-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.property-views-info {
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: rgba(235, 244, 223, 1);
  border-radius: 10.99px;
}

.views-icon {
  width: 28px;
  margin-right: 8px;
}

.property-views-text {
  font-size: 14px;
}

.property-info-details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.property-info-details-list {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.property-info-postedBy {
  width: 100%;
}

.property-info-title {
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.5%;
  color: rgba(71, 71, 71, 1);
}

.property-info-location {
  color: rgba(125, 125, 125, 1);
  font-size: 14px;
  font-weight: 400;
  margin: 7px 0;
  /* word-spacing: px; */
}

.property-info-subtitle {
  color: rgba(71, 71, 71, 1);
  font-size: 15px;
  font-weight: 400;
  margin: 5px 0;
  word-spacing: 5px;
}

.success-tick-icon {
  width: 20px;
  height: 20px;
  margin-left: 4px;
  margin-top: -8px;
}

.property-info-postedby-text {
  color: rgba(71, 71, 71, 1);
  font-size: 14px;
  font-weight: 500;
}

.property-info-agent-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  cursor: pointer;
  width: 100%;
}

.agent-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.agent-details-posted-by-text {
  font-size: 12px;
  margin-left: 8px;
}

.agent-name {
  color: rgba(96, 13, 180, 1);
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-left: 8px;
}

.agent-star {
  color: rgba(71, 71, 71, 1);
  font-size: 16px;
  font-weight: 500;
  margin-left: 7px;
}

.agent-rating {
  word-spacing: 3px;
}

.agent-verified-icon {
  margin-left: 5px;
  width: 16px;
  height: 16px;
}

.agent-reviews {
  color: rgba(125, 125, 125, 1);
  font-size: 14px;
  font-weight: 400;
}

.agent-profile-details {
  margin: 5px;
  margin-left: 0;
  margin-top: 13px;
}

.agent-profile-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.property-info-rooms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  gap: 20px;
}

.property-rooms {
  padding: 10px 25px;
  border-radius: 8px;
  border: 1px solid rgba(210, 210, 210, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.property-room-count {
  font-size: 30px;
  font-weight: 500;
  color: rgba(96, 13, 180, 1);
  display: inline-block;
  margin-right: 10px;
}

.property-room-type {
  color: rgba(125, 125, 125, 1);
  font-size: 16px;
  font-weight: 500;
}

.property-box-border {
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 8px;
  margin: 20px 0;
}

.property-overview {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.property-rent-details {
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  background-color: #f4f0df;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.property-rent-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.property-rent-details-label {
  width: 30%;
  padding: 5px;
  color: #7d7d7d;
  font-size: 14px;
}

.property-rent-details-value {
  font-size: 14px;
  text-wrap: nowrap;
  color: #474747;
  padding: 5px;
}

.property-overview-title {
  width: 100%;
  color: rgba(71, 71, 71, 1);
  font-size: 22px;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 10px;
}

.property-details-description {
  padding: 10px;
}

.property-overview-items {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
}

.property-overview-label {
  color: rgba(125, 125, 125, 1);
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0;
}

.property-overview-value {
  color: rgba(71, 71, 71, 1);
  font-size: 16px;
  font-weight: 500;
}

.property-amenties-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
}

.property-amenties-value {
  background-color: #f5edff;
  padding: 10px;
  margin: 10px;
  margin-left: 0;
  border-radius: 8px;
}

.property-docs-items {
  width: 30%;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 7px;
  cursor: pointer;
}

.property-doc-name {
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.property-doc-size {
  font-size: 12px;
  font-weight: 400;
}

.property-liv-score {
  padding-left: 10px;
}

.chat-unlocked-container {
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-unlocked-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.chat-unlocked-success-tick-icon {
  width: 100px;
  height: 100px;
}

.chat-unlocked-title {
  font-size: 22px;
  color: rgba(71, 71, 71, 1);
  text-align: center;
  margin-bottom: 12px;
}

.chat-unlocked-subtitle {
  color: rgba(71, 71, 71, 1);
  font-size: 14px;
}

@media screen and (max-width: 700px) {
  .property-detail-container {
    width: 470px !important;
  }
}

@media screen and (max-width: 700px) {
  .property-detail-container {
    width: 360px !important;
  }
}
